<template>
  <v-card elevation="0">
    <v-card-title class="headline">
      Machine Schedule {{ machine_id }}
    </v-card-title>
    <!-- <machine-schedule :machine="editedItem"></machine-schedule>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="scheduleCancel"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="scheduleSave"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions> -->
  </v-card>
</template>

<script>
export default {
  props: {
    machine_id: {
      type: String,
      default: ''
    }
  }
};
</script>

<style></style>
